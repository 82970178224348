<template>
  <div>
    <div class="flex">
      <div>
        <div class="dashboard-main">
          <div class="cat">
            <v-progress-linear
              style="margin: 4px 4px 4px 4px"
              v-model="progress"
            ></v-progress-linear>
            <div v-if="selectedClass.classID === 'admin'" class="cat-item">
              <label for="className">Название</label>
              <input
                type="text"
                class="form-control"
                required
                v-model="nameClub"
              />
              <label for="className">Где находится?</label>
              <select
                @change="changeLocation"
                class="form-select"
                id=""
                v-model="locationClub"
              >
                <option></option>
                <option>Школа</option>
                <option>Станица</option>
                <option>ст. Натухаевская</option>
                <option>Город</option>
                <option>Анапа</option>
              </select>
              <label for="className">Уровень</label>

              <select
                v-if="locationClub === 'Школа'"
                class="form-select"
                id=""
                v-model="levelClub"
              >
                <option>Другое</option>
                <option>Кружок</option>
                <option>Объединение</option>
                <option>Спортивная секция</option>
                <option>Точка роста (Школа)</option>
              </select>
              <select
                v-if="locationClub === 'Анапа'"
                class="form-select"
                id=""
                v-model="levelClub"
              >
                <option></option>
                <option>ЧАСТНЫЕ УЧРЕЖДЕНИЯ</option>
                <option>МБУ ДО ДЮСШ № 7</option>
              </select>
              <select
                v-if="locationClub === 'Станица'"
                class="form-select"
                id=""
                v-model="levelClub"
              >
                <option>Другое</option>
                <option>ЧАСТНЫЕ УЧРЕЖДЕНИЯ</option>
                <option>МБУ «СШ „Раевская имени Ю.И.Гордеева“</option>
                <option>МАУ СШ «Пегас»</option>
                <option>ДШИ</option>
                <option>ДК ст. Раевская</option>
              </select>
              <select
                v-if="locationClub === 'Город'"
                class="form-select"
                id=""
                v-model="levelClub"
              >
                <option>Другое</option>
                <option>ЧАСТНЫЕ УЧРЕЖДЕНИЯ</option>
                <option>Детская школа искусств им. Л.А. Гергиевой</option>
                <option>
                  Муниципальное бюджетное учреждение дополнительного образования
                  «ЦЕНТР ДЕТСКОГО ТВОРЧЕСТВА»
                </option>
                <option>МБУ Городской Дворец Культуры</option>
                <option>Морской культурный центр</option>
                <option>
                  Дворец творчества детей и молодежи им. Н.И.Сипягина
                  г.Новороссийск
                </option>
                <option>
                  КВАНТОРИУМ «Центр детского и юношеского технического
                  творчества» (ГБУ ДО КК ЦДЮТТ)
                </option>
                <option>
                  МБУ ДО "ДМШ №1 им. А.С. Данини МО г. Новороссийск"
                </option>
                <option>МБУ ДО ДЮСШ "ОЛИМПИЕЦ"</option>
                <option>Спортивный комплекс "ПАТРИОТ"</option>
              </select>
              <select
                v-if="locationClub === 'ст. Натухаевская'"
                class="form-select"
                id=""
                v-model="levelClub"
              >
                <option>Другое</option>
                <option>ЧАСТНЫЕ УЧРЕЖДЕНИЯ</option>
                <option>МБУ ДО ДЮСШ «КАИССА»</option>
              </select>
              <label for="className">Профиль</label>
              <select
                class="form-select"
                v-model="profileClub"
                id="profileSelect"
              >
                <option>художественный</option>
                <option>технический</option>
                <option>естественнонаучный</option>
                <option>туристско-краеведческий</option>
                <option>социально-педагогический</option>
                <option>физкультурно-спортивный</option>
              </select>
              <button
                @click="createClub()"
                class="btn btn-success"
                id="test"
                style="width: auto"
              >
                Создать направление дополнительного <br />образования (кружок)
              </button>
              <div>
                Название:{{ nameClub }} <br />
                Место:{{ locationClub }} <br />{{ levelClub }} <br />{{
                  profileClub
                }}
              </div>
            </div>
          </div>
          <div>
            <button
              class="btn btn-danger"
              style="margin-left: 15px"
              @click="showModal"
            >
              Показать список кружков
            </button>
          </div>
          <div
            class="dashboard-main"
            style="background-color: #f8f8f8; max-width: 400px"
          >
            <b>Статистика по занятости (выводит количество детей!):</b>
            <br />
            <ul class="statClubs" id="stat">
              <li class="statClubs">
                Всего учеников: <b> &nbsp;{{ classList.length }}</b>
              </li>
              <li class="statClubs">
                С занятостью:
                <b>&nbsp;{{ countStudentWithClubs }}&nbsp;</b> ({{
                  ((countStudentWithClubs / classList.length) * 100).toFixed(2)
                }}%)
              </li>
              <li class="statClubs">
                Не заняты:
                <b
                  >&nbsp;{{
                    classList.length - countStudentWithClubs
                  }}
                  &nbsp;</b
                >({{
                  (
                    ((classList.length - countStudentWithClubs) /
                      classList.length) *
                    100
                  ).toFixed(2)
                }}%)
              </li>
            </ul>
            <hr />
            <ul class="statClubs" id="stat">
              <li
                v-for="(location, index) in clubsLocationStatPrint"
                :key="index"
                class="statClubs"
              >
                {{ location.location }}: <b> &nbsp;{{ location.count }}</b>
              </li>
            </ul>
            <hr />
            <ul class="statClubs" id="stat">
              <li
                v-for="(level, index) in clubsLevelStatPrint"
                :key="index"
                class="statClubs"
              >
                {{ level.level }}: <b> &nbsp;{{ level.count }}</b>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="dashboard-main">
        <div style="display: flex">
          <select
            class="form-select"
            v-model="selectFilterClass"
            @change="changeClass"
            id="selectClassPrint"
          >
            <option v-for="(clas, index) in classArray" :key="index">
              {{ clas.className }}
            </option>
            <option>Все</option>
            <option>Все занятые</option>
            <option>Не занятые</option>
          </select>
          <div style="padding: 7px 0px 0px 7px">
            Заняты: {{ filteredListCountClubs }}
          </div>
          <button class="btn btn-secondary" @click="printClassList()">
            Печать
          </button>
        </div>
        <div
          style="display: flex"
          v-if="this.selectFilterClass === 'Все занятые'"
        >
          <button
            type="button"
            class="btn btn-outline-secondary dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style="width: 200px; height: 50px"
          >
            Фильтр по уровню
          </button>
          <ul
            class="dropdown-menu"
            data-toggle="popover"
            style="background-color: #f2f2f2"
          >
            <div
              class="btn-group dropend"
              v-for="(level, index) in clubsLevelStatPrint"
              :key="index"
              id="divOrder"
            >
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                :id="level.level"
                v-model="level.checked"
                style="
                  width: 30px;
                  height: 30px;
                  margin-left: 0px;
                  margin-right: 10px;
                  font-size: 11px;
                "
              />
              <label
                class="form-check-label"
                :for="level.level"
                style="margin-top: 8px"
              >
                {{ level.level }}
              </label>
              <br />
            </div>
          </ul>

          <div style="margin-left: 10px">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
              v-model="selectTR"
              style="width: 30px; height: 30px; margin-right: 10px"
            />
            <label
              class="form-check-label"
              for="flexCheckDefault"
              style="margin-top: 8px"
            >
              Использовать фильтр
            </label>
          </div>
        </div>

        <table style="padding-top: 20" class="reciept-table" id="sheetjs">
          <tr>
            <th>№</th>
            <th>Класс</th>
            <th>Фамилия</th>
            <th>Имя</th>
            <th>Категория</th>
            <th>Кружки</th>
          </tr>
          <tr v-for="(student, index) in filteredList" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ student.classPrint }}</td>
            <td>{{ student.FirstName }}</td>
            <td>{{ student.LastName }}</td>
            <td>{{ student.Category }}</td>
            <td>
              <ul v-for="(club, index) in student.clubsPrint" :key="index">
                <li id="liPrint">
                  {{ club.name }} ({{ club.location }}) {{ club.level }}
                </li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
      <div class="dashboard-main">
        Показать список учеников отдельного направления:

        <div>
          <select
            style="width: auto"
            class="form-select"
            id="filterClubs"
            v-model="filterAllClubs"
            @change="fliterClubsList(filterAllClubs)"
          >
            <option
              v-for="(club, index) in clubsList"
              :key="index"
              v-bind:value="club"
            >
              {{ club.location }}_{{ club.level }}_{{ club.name }}
            </option>
          </select>
        </div>
        <div>
          <button class="btn btn-success" @click="studentListByClub()">
            Получить список
          </button>
        </div>
        <div>
          <table style="padding-top: 20px" class="reciept-table">
            <tr>
              <th>№</th>
              <th>Класс</th>
              <th>Фамилия</th>
              <th>Имя</th>
            </tr>
            <tr v-for="(student, index) in studentsPrintList" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ student.classPrint }}</td>
              <td>{{ student.FirstName }}</td>
              <td>{{ student.LastName }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="dashboard-main">
        <div>
          <button
            class="btn btn-success"
            @click="consolidatedReturnClassCount()"
          >
            Показать свод по классам
          </button>
        </div>
        <div>
          <table style="padding-top: 20px" class="reciept-table">
            <tr>
              <th>№</th>
              <th>Класс</th>
              <th>Всего детей</th>
              <th>Количество занятых</th>
              <th>% занятости</th>
            </tr>
            <tr v-for="(c, index) in consolidatedReturnClass" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ c.className }}</td>
              <td>{{ c.countStudent }}</td>
              <td
                v-if="c.count"
                :class="{
                  'color-black': c.count > 0,
                }"
              >
                {{ c.count }}
              </td>
              <td
                v-if="!c.count"
                :class="{
                  'color-red': c.count == 0,
                }"
              >
                {{ c.count }}
              </td>
              <td>{{ ((c.count / c.countStudent) * 100).toFixed(1) }}%</td>
            </tr>
          </table>
        </div>
      </div>

      <modal ref="modal" v-show="showModalCheck" @close="showModal()" />
    </div>
  </div>
</template>
<style scoped>
@import "../assets/style.css";
.color-black {
  color: black;
}
.color-red {
  color: red;
}

li {
  list-style-type: none; /* Убираем маркеры */
  text-align: left;
  font-size: 12px;
  line-height: 0.8;
}
ul {
  margin: 4px;
  margin-left: 0; /* Отступ слева в браузере IE и Opera */
  padding-left: 0; /* Отступ слева в браузере Firefox, Safari, Chrome */
  text-align: left;
}

.statClubs {
  font-size: 16px;
  line-height: 20px;
  text-align: left;
}
select {
  max-width: 250px;
}
#test {
  display: block;
  width: 200px;
  height: 80px;
  overflow: hidden;
  white-space: pre-wrap;
  word-break: break-all;
  text-overflow: "_";
  hyphens: auto;
}

#filterClubs {
  width: 800px;
}

.dashboard-main {
  border-radius: 3px;
  box-shadow: 0 0 3px #4d4d4d;
  padding: 10px 10px 10px 10px;
  margin: 15px 10px 15px 15px;
  min-height: auto;
}
</style>
<script>
//import * as XLSX from "xlsx";
import TutorialDataService from "../services/TutorialDataService";
import ServerCommandLogin from "../services/ServerCommandLogin";
import modal from "./ClubsModal.vue";
export default {
  components: {
    modal,
  },
  props: {
    selectedClass: Object,
  },

  data() {
    return {
      nameClub: "",
      locationClub: "",
      levelClub: "",
      profileClub: "",
      showModalCheck: false,
      classList: [],
      classArray: [],
      clubsList: [],
      studentsPrintList: [],
      selectFilterClass: "",
      filterAllClubs: "",
      tempClubsLocationList: [],
      clubsLocationStatPrint: [],
      clubsLevelStatPrint: [],
      consolidatedReturnClass: [],
      progress: 0,
      selectTR: false,
      showFilterLevel: false,
    };
  },
  methods: {
    check() {
      console.log(this.clubsLevelStatPrint);
    },
    consolidatedReturnClassCount() {
      this.consolidatedReturnClass = [];
      let tempObj;
      let count = 0;
      let countStudent = 0;
      for (let i = 0; i < this.classArray.length; i++) {
        for (let j = 0; j < this.classList.length; j++) {
          if (this.classList[j].classPrint == this.classArray[i].className) {
            countStudent += 1;
            if (this.classList[j].clubsPrint.length > 0) count += 1;
          }
        }

        tempObj = {
          className: this.classArray[i].className,
          count: count,
          countStudent: countStudent,
        };
        this.consolidatedReturnClass.push(tempObj);
        count = 0;
        countStudent = 0;
      }
    },
    fliterClubsList(filterAllClubs) {
      console.log(filterAllClubs);
    },

    printClassList() {
      let tbl = document.getElementById("sheetjs").innerHTML;
      let stat = document.getElementById("stat").innerHTML;
      // let file = XLSX.utils.table_to_sheet(tbl);
      // console.log(file);
      // const wb = XLSX.utils.book_new();
      // XLSX.utils.book_append_sheet(wb, file, "Export");
      // XLSX.writeFile(wb, "SheetJSMultiTablexport.xlsx");
      console.log(tbl);
      let top = "<head>" + "</head><body><table>";
      let tab = window.open(
        "",
        "",
        "left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0"
      );
      let style =
        "<style type='text/css'>ul {padding-left: 0; padding-top: 3px;} li{list-style-type: none; } #liPrint{line-height: 0.2;} table {border: 1px solid black; border-collapse: collapse; font-size:10px}  th,td { padding: 2px;border: 1px solid black; } </style> ";
      let foot = "</table></body>";
      let print = top + stat + tbl + style + foot;
      tab.document.write(print);
      tab.document.close();
      tab.focus();

      tab.print();

      tab.onfocus = function () {
        setTimeout(function () {
          tab.close();
        }, 500);
      };
    },
    studentListByClub() {
      this.studentsPrintList = [];

      for (let j = 0; j < this.classList.length; j++) {
        for (let k = 0; k < this.classList[j].Clubs.length; k++) {
          if (this.filterAllClubs._id == this.classList[j].Clubs[k]) {
            this.studentsPrintList.push(this.classList[j]);
            break;
          }
        }
      }
      console.log(this.studentsPrintList);
      this.studentsPrintList.sort(
        (a, b) => (a.classPrint > b.classPrint ? 1 : -1),
        (a, b) => (a.FirstName > b.FirstName ? 1 : -1)
      );
      this.progress += 40;
    },
    clubsInClassList() {
      for (let i = 0; i < this.classList.length; i++) {
        this.$set(this.classList[i], "clubsPrint", []);

        for (let j = 0; j < this.clubsList.length; j++) {
          for (let k = 0; k < this.classList[i].Clubs.length; k++) {
            if (this.classList[i].Clubs[k] == this.clubsList[j]._id) {
              this.classList[i].clubsPrint.push({
                name: this.clubsList[j].name,
                location: this.clubsList[j].location,
                level: this.clubsList[j].level,
              });
            }
          }
        }
      }
      this.progress += 10;
      this.statLocationClubs();
    },

    statLocationClubs() {
      let clubsLocationAll = new Set();
      let clubsLevelAll = new Set();
      let countTemp = new Set();
      let countTemp2 = new Set();
      for (let i = 0; i < this.clubsList.length; i++) {
        clubsLocationAll.add(this.clubsList[i].location);
        clubsLevelAll.add(this.clubsList[i].level);
      }
      console.log(clubsLocationAll);
      let arrayLoc = Array.from(clubsLocationAll);
      let arrayLevel = Array.from(clubsLevelAll);
      let tempObj;
      for (let j = 0; j < arrayLoc.length; j++) {
        tempObj = { location: arrayLoc[j], count: 0, students: countTemp };
        this.clubsLocationStatPrint.push(tempObj);
      }
      for (let j = 0; j < arrayLevel.length; j++) {
        tempObj = { level: arrayLevel[j], count: 0, students: countTemp2 };
        this.clubsLevelStatPrint.push(tempObj);
      }
      for (let j = 0; j < arrayLoc.length; j++) {
        for (let i = 0; i < this.classList.length; i++) {
          if (this.classList[i].clubsPrint.length != 0) {
            console.log(this.classList[i].clubsPrint);

            for (let k = 0; k < this.classList[i].clubsPrint.length; k++)
              if (this.classList[i].clubsPrint[k].location == arrayLoc[j]) {
                countTemp.add(this.classList[i]._id);
                // this.clubsLocationStatPrint[j].count += 1;
              }
          }
        }
        this.clubsLocationStatPrint[j].count = countTemp.size;
        countTemp.clear();
      }

      for (let j = 0; j < arrayLevel.length; j++) {
        for (let i = 0; i < this.classList.length; i++) {
          if (this.classList[i].clubsPrint.length != 0) {
            for (let k = 0; k < this.classList[i].clubsPrint.length; k++)
              if (this.classList[i].clubsPrint[k].level == arrayLevel[j]) {
                // this.clubsLevelStatPrint[j].count += 1;
                countTemp2.add(this.classList[i]._id);
              }
          }
        }
        this.clubsLevelStatPrint[j].count = countTemp2.size;
        countTemp2.clear();
      }
      this.progress += 40;
    },
    changeClass() {
      console.log(this.selectFilterClass, this.classList);
    },

    getClubs() {
      TutorialDataService.getClubs()
        .then((response) => {
          var a = new Array();
          a = Object.values(response.data).sort(
            (a, b) => (a.location > b.location ? 1 : -1),
            (a, b) => (a.level > b.level ? 1 : -1)
          );

          this.clubsList = a;
          this.clubsInClassList();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getStudents(data) {
      if (data.classID != "admin") {
        this.classArray[0] = this.selectedClass;

        TutorialDataService.findStudentByClassID(data)
          .then((response) => {
            this.classList.splice(response.data);
            var a = new Array();
            a = Object.values(response.data);
            a.sort((a, b) => (a.FirstName < b.FirstName ? 1 : -1));

            for (var i = 0; i < a.length; i++) {
              a[i].classPrint = this.selectedClass.className;
              this.$set(this.classList, i, a[i]);
            }
            this.progress += 60;
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        await ServerCommandLogin.getAllCLass().then((res) => {
          this.classArray = res.data.map(this.getDisplayClass);
        });
        await TutorialDataService.findStudentByClassID()
          .then((response) => {
            this.classList.splice(response.data);
            var a = new Array();
            a = Object.values(response.data);
            for (var i = 0; i < a.length; i++) {
              for (let j = 0; j < this.classArray.length; j++) {
                if (this.classArray[j].classID == a[i].classID) {
                  a[i].classPrint = this.classArray[j].className;
                  this.$set(this.classList, i, a[i]);
                }
              }
            }
            this.classList.sort(
              (a, b) => (a.classPrint > b.classPrint ? 1 : -1),
              (a, b) => (a.FirstName < b.FirstName ? 1 : -1)
            );
            this.selectFilterClass = this.classArray[0].name;
            this.progress += 60;
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    showModal() {
      this.showModalCheck = !this.showModalCheck;
      this.$refs.modal.getClubs();
    },
    changeLocation() {
      this.levelClub = null;
    },
    createClub() {
      let data = {
        name: this.nameClub,
        location: this.locationClub,
        level: this.levelClub,
        profile: this.profileClub,
      };
      TutorialDataService.crateClub(data)
        .then((response) => {
          console.log("УСПЕШНО ОТПРАВЛЕНО", response);
          this.nameClub = null;
          this.levelClub = null;
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
    },

    getDisplayClass(data) {
      return {
        classID: data._id,
        className: data.className,
      };
    },
  },
  async mounted() {
    await this.getStudents(this.selectedClass);
    await this.getClubs();
  },
  computed: {
    filteredList: function () {
      var clas = this.selectFilterClass;
      let selectTR = this.selectTR;
      let filterLevel = this.clubsLevelStatPrint;

      let clasLength;
      if (clas) {
        clasLength = clas.length;
      } else {
        clasLength = 2;
      }

      return this.classList
        .filter(function (elem) {
          if (clas === "") return elem;
          if (clas === "Не занятые") return elem.clubsPrint.length == 0;
          if (clas != "Все") {
            if (clas === "Все занятые") {
              if (selectTR == true) {
                let tempElem = null;
                console.log(filterLevel, "filterLevel");
                for (let j = 0; j < filterLevel.length; j++) {
                  if (filterLevel[j].checked) {
                    for (let i = 0; i < elem.clubsPrint.length; i++) {
                      console.log(elem.clubsPrint[i]);
                      if (elem.clubsPrint[i].level == filterLevel[j].level) {
                        tempElem = elem;
                      }
                    }
                  }
                }

                return tempElem;
              } else return elem.clubsPrint.length > 0;
            }
            if (elem.classPrint.length === clasLength) {
              return elem.classPrint.indexOf(clas, 0, 1, clas) > -1;
            }
          } else return elem;
        })
        .sort(
          (a, b) => (a.classPrint > b.classPrint ? 1 : -1),
          (a, b) => (a.FirstName < b.FirstName ? 1 : -1)
        );
    },
    filteredListCountClubs: function () {
      let count = 0;
      for (let i = 0; i < this.filteredList.length; i++) {
        if (this.filteredList[i].Clubs.length != 0) {
          count += 1;
        }
      }

      return count;
    },
    countStudentWithClubs: function () {
      let count = 0;
      for (let i = 0; i < this.classList.length; i++) {
        if (this.classList[i].Clubs.length != 0) {
          count += 1;
        }
      }

      return count;
    },
  },
};
</script>

<style scoped>
#divOrder {
  display: flex;
  align-items: column;
  width: auto;
  margin: 5px;
  background-color: #fcfcfc;
}
</style>
